import { AnimationsService } from './../../services/animations.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalPopupComponent } from 'src/app/components/modal-popup/modal-popup.component';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray } from '@angular/forms';
import { HttpClientService } from 'src/app/services/http-client.service';
import { SingletonService } from 'src/app/services/singleton.service';
import { UserService } from 'src/app/services/user.service';
import * as _ from 'lodash';

@Component({
	selector: 'app-events',
	templateUrl: './events.component.html',
	styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {

	@ViewChild('eventModal',{static:false}) eventModal: ModalPopupComponent;
	eventFormGroup: FormGroup;
	companyList: any;
	tempObj: any = [];
	socialMedia: any;
	eventsList: any;
	eventTypes: any;
	color = 'primary';
	display: boolean;
	otherEventDisplay: boolean = false;
	socialMediaList: any;

	constructor(private fb: FormBuilder,private httpClient: HttpClientService,
		private ss: SingletonService,
		private user: UserService, private formBuilder: FormBuilder) {
		this.eventFormGroup = this.formBuilder.group({
			'eventTypeId': ['', Validators.required],
			'otherEventType': [''],
			'companyId': ['', Validators.required],
			'socialMedia': this.fb.array([
				this.fb.group({
					'socialMediaId': [],
					'isChecked': [''],
					'isRepost': ['']
				})
			])
		});
	}

	ngOnInit() {
		this.getEventsList();
	}

	openeventModal() {
		this.getEventTypes();
		this.eventFormGroup.reset();
		this.getSocialMediaList();
		this.getCompanyDetails();
		this.eventModal.open();
	}

	addEventSubmit(value) {
		if (this.eventFormGroup.valid) {
			let i = 0;
			i = _.filter(value.socialMedia, ['isChecked', true]).length;
			this.eventTypes.forEach(element => {
				if (element.e_type_name == value.eventTypeId) {
					value['eventTypeId'] = element.e_type_id;
				}
			});
			value['smTypes'] = value.socialMedia;
			if (i > 0) {
				this.httpClient.request('post', "company/add-event", '', value).subscribe((res: any) => {
					if (res.status === 200) {
						if (res.error.text == "Event added successfully") {
							this.ss.statusMessage.showStatusMessage(true, 'Event added sucessfully');
							this.eventModal.close();
							this.getCompanyDetails();
							this.getEventsList();
							this.closePopup();
						}
						else if (res.error.text == "Event already exist") {
							this.ss.statusMessage.showStatusMessage(false, 'Event already exist', 3000, 'exist');
						}
					}
					else {
						this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
					}
				});
			}
			else {
				this.ss.statusMessage.showStatusMessage(false, "Select atleast one social media!");
			}
		}
	}

	getEventsList(){
		this.httpClient.request('get', "company/get-eventsList").subscribe((res: any) => {
			if (res.status === 200) {
				this.eventsList = res.body;
			}
			else {
				this.ss.statusMessage.showStatusMessage(false, "Something went wrong cannot get events list");
			}
		});
	}

	getEventTypes(){
		this.httpClient.request('get', "company/getEventTypes").subscribe((res: any) => {
			if (res.status === 200) {
				this.eventTypes = res.body;
			}
			else {
				this.ss.statusMessage.showStatusMessage(false, "Something went wrong cannot get event types");
			}
		});
	}

	getCompanyDetails() {

		this.httpClient.request('get', "company/get-companyDropdownList").subscribe((res: any) => {
			if (res.status === 200) {
				this.companyList = res.body;

			}
			else {
				this.ss.statusMessage.showStatusMessage(false, "Something went wrong cannot get company list");
			}
		});
	}

	getSocialMediaList() {
		this.httpClient.request('get', "company/get-socialMediaList").subscribe((res: any) => {
			if (res.status === 200) {
				this.socialMedia = res.body;
				
			}
			else {
				this.ss.statusMessage.showStatusMessage(false, "Something went wrong cannot get socialMedia list");
			}
		});
	}

	getSocialMedia(companyId) {
		this.display = false;
		this.emptyFormArray(<FormArray>this.eventFormGroup.get('socialMedia'));
		this.httpClient.request('get', "followers/get-company-socialMedia", 'companyId=' + companyId).subscribe((res: any) => {
			if (res.status === 200) {
				this.socialMediaList = res.body;
				for (let i = 0; i < this.socialMediaList.length; i++) {
					let temp = this.fb.group({
						'socialMediaId': [''],
						'isChecked': [''],
						'isRepost': ['']
					})
					temp['controls'].socialMediaId.setValue(this.socialMediaList[i].social_media_type);
					let fa = <FormArray>this.eventFormGroup.get('socialMedia');
					fa.push(temp);
					this.display = true;
				}
			}
			else {
				this.ss.statusMessage.showStatusMessage(false, "Something went wrong cannot get social media list for the selected company");
			}
		});
	}

	emptyFormArray(formArray: FormArray) {
		if (formArray != null && formArray != undefined && formArray.length > 0) {
			formArray.removeAt(formArray.length - 1);
			this.emptyFormArray(formArray);
		} else {
			return;
		}
	}

	displayOtherEventName(eventType) {
		if (eventType == 'Others') {
			this.otherEventDisplay = true;
			this.eventFormGroup.get('otherEventType').setValidators(Validators.required);
		} else {
			this.otherEventDisplay = false;
			this.eventFormGroup.get('otherEventType').setValidators(null);
		}
	}

	// close the popup
    closePopup() {
        console.log('closed modal pop up');
        this.eventModal.animationService.fadeOutUp(this.eventModal.overlay.nativeElement);
        clearInterval(this.eventModal.timer);
		this.eventModal.closeEvent.emit(this.eventModal.idd);

		this.eventFormGroup.reset();
		this.otherEventDisplay = false;
		this.display = false;
    }
}
