import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { SidebarComponent } from '../layout/sidebar/sidebar.component'
import { LoaderComponent } from '../components/loader/loader.component';
import { ProgressBarComponent } from '../components/progress-bar/progress-bar.component';
import { StatusMessageComponent } from '../components/status-message/status-message.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { UAParser } from 'ua-parser-js';
import {uaParser} from './../interfaces/ua-parser';
import { ChartOptions } from 'chart.js';
import { Color, BaseChartDirective, Label } from 'ng2-charts';

@Injectable({
  providedIn: 'root'
})
export class SingletonService {


  isMenuOpenSubject: Subject<boolean> = new Subject();
  setactive: Subject<number> = new Subject();
  knowFrames: Subject<string> = new Subject();

  loader: LoaderComponent;

  progressBar: ProgressBarComponent;

  statusMessage: StatusMessageComponent;

  notificationCount : number;


  // baseUrl = "http://localhost:3009/"; 
  baseUrl = "https://markat.atai.ai/";

  
  // baseUrl = "http://10.50.75.135:3009/"; 

  login = 'api/login';

  svg: String[] = []; // used to save the state and not send http requests after an svg sprite is available

  svgElements: Object[] = []; // used to store different svg sprites, svgs to be used in the page 

  
  presets: { presetLabel: string; range: { fromDate: Date; toDate: Date; }; }[];
  
  // client_config io_config
  constructor(private _http:HttpClient) { 
  
  }

  /************ Start BREADCRUMB  ************/
  public breadCrumbMap: Array<any> = [];

  public breadCrumbSubject: Subject<any> = new Subject();

  setBreadCrumbData(key, value, noclick: boolean = false, queryParamsObj: Object = {}) {
    // set the breadcrumb text value as part of the data available for the breadcrumb
    this.breadCrumbMap[key] = value;
    // send the noclick boolean into the data available for the breadcrumb
    this.breadCrumbMap[key + '_noclick'] = noclick;
    // send the query Params if any into the data available for the breadcrumb
    this.breadCrumbMap[key + '_queryParams'] = queryParamsObj;

    this.breadCrumbSubject.next();
  }

  loggedIn$ : Subject<boolean> = new Subject();

  /************** END Bread CRumb implementation ************/
  setActive(userid: number) {
    this.setactive.next(userid)
  }

  getFrames(name: string) {
    this.knowFrames.next(name)
    console.log(this.knowFrames);
  }

  setupPresets() {
    const backDate = numOfDays => {
      // tslint:disable no-shadowed-variable
      const today = new Date();
      return new Date(today.setDate(today.getDate() - numOfDays));
    };

    const today = new Date();
    const yesterday = backDate(1);
    const minus7 = backDate(8);
    const minus30 = backDate(30);
    const currMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
    const currMonthEnd = new Date();
    // new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);

    this.presets = [
      {
        presetLabel: 'Yesterday',
        range: { fromDate: yesterday, toDate: yesterday }
      },
      {
        presetLabel: 'Last 7 Days',
        range: { fromDate: minus7, toDate: yesterday }
      },
      {
        presetLabel: 'Last 30 Days',
        range: { fromDate: minus30, toDate: yesterday }
      },
      {
        presetLabel: 'This Month',
        range: { fromDate: currMonthStart, toDate: yesterday }
      },
      {
        presetLabel: 'Today',
        range: { fromDate: today, toDate: today}
      }
      // ,{
      //   presetLabel: 'Last Month',
      //   range: { fromDate: lastMonthStart, toDate: lastMonthEnd }
      // }
    ];
  }
  
public lineChartOptionsDisable: (ChartOptions & { annotation: any }) = {
  responsive: true,
//   legend: {
//     onClick: (e:any,legendItem) =>{
//       var index = legendItem.datasetIndex;
//       console.log(legendItem);
      
//         if(index == 2){

//         }
//         else{

//         }
//         console.log(index);
        
//     }
// },
legend: {
  position: 'top',
  display: true,
  fullWidth: true,
  labels: {
    fontSize: 14,
  }
},
  scales: {
 
    // We use this empty structure as a placeholder for dynamic theming.
    xAxes: [{
      scaleLabel: {
        display: true,
        labelString: 'Day Hour(24 Hour)'
      },
      offset: true
    }],
    yAxes: [{
      scaleLabel: {
        display: true,
        labelString: 'Images'
      },
        id: 'y-axis-0',
        position: 'left',
      }
    ]
  },
  annotation: {
    annotations: [
      {
        type: 'line',
        mode: 'vertical',
        scaleID: 'x-axis-0',
        value: 'March',
        borderColor: 'orange',
        borderWidth: 2,
        label: {
          enabled: true,
          fontColor: 'orange',
          content: 'LineAnno'
        }
      },
    ],
  },
};

  public lineChartOptions: (ChartOptions & { annotation: any }) = {
    responsive: true,
    legend: {
      labels: {
        fontSize: 14,
      }
    },
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Date'
        },
        offset: true
      }],
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Images'
        },
          id: 'y-axis-0',
          position: 'left',
        }
      ]
    },
    annotation: {
      annotations: [
        {
          type: 'line',
          mode: 'vertical',
          scaleID: 'x-axis-0',
          value: 'March',
          borderColor: 'orange',
          borderWidth: 2,
          label: {
            enabled: true,
            fontColor: 'orange',
            content: 'LineAnno'
          }
        },
      ],
    },
  };
  
  public barChartColors: Color[] = [
    { // red
      backgroundColor: 'rgba(63, 149, 205,1)',
      borderColor: '#3e95cd',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // green
      backgroundColor: 'rgba(125, 104, 171,1)',
      borderColor: '#8e5ea2',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    },
    { // blue
      backgroundColor: 'rgba(255,99,132,1)',
      borderColor: 'rgba(255,99,132,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // orange
      backgroundColor: 'rgba(43, 106, 146,1)',
      borderColor: '#3cba9f',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // purple
      backgroundColor: 'rgba(196, 87, 80,1)',
      borderColor: '#c45850',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }
  ];

  public lineChartColors: Color[] = [
    { // red
      backgroundColor: 'rgba(63, 149, 205,0.2)',
      borderColor: '#3e95cd',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // green
      backgroundColor: 'rgba(125, 104, 171,0.2)',
      borderColor: '#8e5ea2',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    },
    { // blue
      backgroundColor: 'rgba(255,99,132,0.2)',
      borderColor: 'rgba(255,99,132,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // orange
      backgroundColor: 'rgba(43, 106, 146,0.2)',
      borderColor: '#3cba9f',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // purple
      backgroundColor: 'rgba(196, 87, 80,0.2)',
      borderColor: '#c45850',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }
  ];

  public lineChartColorsLiveData: Color[] = [
   
    { // green
      backgroundColor: 'rgba(125, 104, 171,0.2)',
      borderColor: '#8e5ea2',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    },
    { // blue
      backgroundColor: 'rgba(255,99,132,0.2)',
      borderColor: 'rgba(255,99,132,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // purple
      backgroundColor: 'rgba(196, 87, 80,0.2)',
      borderColor: '#c45850',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }
  ];

  public annotationChartColorsLiveData: Color[] = [
   
    { // green
      backgroundColor: 'rgba(125, 104, 171,0.2)',
      borderColor: '#004d4d',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    },
    { // blue
      backgroundColor: 'rgba(255,99,132,0.2)',
      borderColor: 'rgb(0, 102, 255)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // purple
      backgroundColor: 'rgba(196, 87, 80,0.2)',
      borderColor: '#800080',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // 
      backgroundColor: 'rgba(76, 29, 39,0.2)',
      borderColor: '#999999',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }
  ];

  getDateTime() {

    let date = new Date();

    let hour:any = date.getHours();
    hour = (hour < 10 ? "0" : "") + hour;

    let min:any = date.getMinutes();
    min = (min < 10 ? "0" : "") + min;

    let sec:any = date.getSeconds();
    sec = (sec < 10 ? "0" : "") + sec;

    let year:any = date.getFullYear();

    let month:any = date.getMonth() + 1;
    month = (month < 10 ? "0" : "") + month;

    let day:any = date.getDate();
    day = (day < 10 ? "0" : "") + day;

    return year + "_" + month + "_" + day + "_" + hour + "_" + min;

}
  

  
}
