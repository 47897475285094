import { ModalPopupComponent } from './components/modal-popup/modal-popup.component';
import { SingletonService } from './services/singleton.service';
import { Component, ViewChild, HostBinding } from '@angular/core';
import * as d3 from 'd3';
import { LoaderComponent } from './components/loader/loader.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { StatusMessageComponent } from './components/status-message/status-message.component';
import { Router, ActivatedRoute } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { UserService } from './services/user.service';
import { NotificationService } from './services/NotificationService';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	router: string;
	url: string;
	role_id: any;
	constructor(
		private ss: SingletonService,
		private _router: Router,
		private activatedRoute: ActivatedRoute,
		private user: UserService
	) {
		this.activatedRoute.url.subscribe(activeUrl => {
			this.url = window.location.pathname;
			console.log(this.url);
		});
	}

	@ViewChild(LoaderComponent, /* TODO: add static flag */ { static: false }) loader;

	@ViewChild(ProgressBarComponent, /* TODO: add static flag */ { static: false }) progressBar;

	@ViewChild(StatusMessageComponent, /* TODO: add static flag */ { static: false }) statusMessage;

	@ViewChild(ModalPopupComponent, /* TODO: add static flag */ { static: false }) modal;

	title = 'Dashboard';

	isMenuVisible: boolean = true;

	isMenuOpen = false;

	sub: any;
	stockQuote: number;

	onClick(e: Event, target) {
		// this.ss.statusMessage.showStatusMessage(false,"No message");
		// this.ss.statusMessage.showStatusMessage(true,"YES message");
		// this.modal.open();
	}


	ngOnInit() {
		this.ss.isMenuOpenSubject.subscribe((value) => {
			this.isMenuOpen = value;
		});

	}
	public screenHeight = screen.height - 177;
	ngAfterViewInit() {
		this.ss.loader = this.loader;
		this.ss.progressBar = this.progressBar;
		this.ss.statusMessage = this.statusMessage;
		this.user.currentMessage.subscribe(val => {
			console.log(val);

		})
		if (this.user.validateSession()){
			this.user.getUserName();
		}
	}


	@HostBinding('class.menu-open') get menuOpen() { return this.isMenuOpen; }
	@HostBinding('class.has-menu') get hasMenu() { return this.isMenuVisible; }

}

