import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormArray } from '@angular/forms';
import { HttpClientService } from 'src/app/services/http-client.service';
import { SingletonService } from 'src/app/services/singleton.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-follower',
  templateUrl: './follower.component.html',
  styleUrls: ['./follower.component.scss']
})
export class FollowerComponent implements OnInit {

  followersFormGroup: FormGroup;
	companyList: any;
	socialMediaList: any;
	display: boolean;


	constructor(private fb: FormBuilder, private httpClient: HttpClientService,
		private ss: SingletonService,
		private user: UserService, private formBuilder: FormBuilder) {
		this.followersFormGroup = this.formBuilder.group({
			'company_id': ['', Validators.required],
			'socialMedia': this.fb.array([
				this.fb.group({
					'socialMediaId': [],
					'followersCount': ['', Validators.required]
				})
			])
		});
	}

	ngOnInit() {
		this.getCompanyDetails();
	}

	getCompanyDetails() {
		this.httpClient.request('get', "company/get-companyDropdownList").subscribe((res: any) => {
			if (res.status === 200) {
				this.companyList = res.body;
			}
			else {
				this.ss.statusMessage.showStatusMessage(false, "Something went wrong cannot get company list");
			}
		});
	}

	getSocialMedia(companyId) {
		this.display = false;
		this.emptyFormArray(<FormArray>this.followersFormGroup.get('socialMedia'));
		this.httpClient.request('get', "followers/get-company-socialMedia", 'companyId=' + companyId).subscribe((res: any) => {
			if (res.status === 200) {
				this.socialMediaList = res.body;
				for (let i = 0; i < this.socialMediaList.length; i++) {
					if (i > 0 || i == 0) {
						let temp = this.fb.group({
							'socialMediaId': [],
							'followersCount': ['', Validators.required]
						})
						temp['controls'].socialMediaId.setValue(this.socialMediaList[i].social_media_type);
						let fa = <FormArray>this.followersFormGroup.get('socialMedia');
						fa.push(temp);
						this.display = true;
					}
				}
			}
			else {
				this.ss.statusMessage.showStatusMessage(false, "Something went wrong cannot get social media list for the selected company");
			}
		});
	}

	updateFollowers(value) {
		if (this.followersFormGroup.valid) {
			this.httpClient.request('post', "followers/update-followers-count", '', value).subscribe((res: any) => {
				if (res.status === 200) {
					if (res.error.text == "Followers added successfully") {
						this.ss.statusMessage.showStatusMessage(true, 'Followers added sucessfully');
						this.display = false;
						this.emptyFormArray(<FormArray>this.followersFormGroup.get('socialMedia'));
						this.followersFormGroup.reset();
					}
					else {
						this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
					}
				}
			})
		}
	}

	emptyFormArray(formArray: FormArray) {
		if (formArray != null && formArray != undefined && formArray.length > 0) {
			formArray.removeAt(formArray.length - 1);
			this.emptyFormArray(formArray);
		} else {
			return;
		}
	}

}
