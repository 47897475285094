import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { UserService } from 'src/app/services/user.service';
import { HttpClientService } from 'src/app/services/http-client.service';
import { ModalPopupComponent } from 'src/app/components/modal-popup/modal-popup.component';
import { SingletonService } from 'src/app/services/singleton.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';

@Component({
	selector: 'dashboard-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
	today: any = new Date();
	userName: string;
	notifyMssg: any = [];
	//@ViewChild('notificationsModal', /* TODO: add static flag */ {static: false}) notificationsModal: ModalPopupComponent;
	count: any;
	reason = new FormArray([]);
	reasonType = new FormArray([]);
	resonsList: any;
	enterReason: boolean = false;
	constructor(
		private datepipe: DatePipe,
		private user: UserService,
		private httpClient: HttpClientService,
		private _router: Router,
		private ss: SingletonService,

	) {



	}

	ngOnInit() {
		this.today = this.datepipe.transform(this.today, 'dd-MMM-yyyy');

	}



	ngAfterViewInit() {
		this.user.user.subscribe(val => {
			this.userName = val;

		})
	}

	logout() {
		this.user.logout();
	}

	getReasons() {
		this.httpClient.request('get', "notification/getReasons").subscribe(res => {
			if (res.status === 200) {
				this.resonsList = res.body;
			}
		})
	}

	checkReasonType(type) {
		if (type == 6) {
			this.enterReason = true;
		}
		else {
			this.enterReason = false;
		}
	}


}
