import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalPopupComponent } from 'src/app/components/modal-popup/modal-popup.component';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { HttpClientService } from 'src/app/services/http-client.service';
import { SingletonService } from 'src/app/services/singleton.service';
import { UserService } from 'src/app/services/user.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent implements OnInit {

	@ViewChild('companyModal',  {static: false}) companyModal: ModalPopupComponent;
	@ViewChild('editCompanyModal',  {static: false}) editCompanyModal: ModalPopupComponent;
	companyFormGroup: FormGroup;
	editCompany: FormGroup;
	companyList: any;
	tempObj : any =[];
	socialMedia: any;
	optionsList: any = ["YES", "NO"];
	editingCompany: any = "";

	constructor(private httpClient: HttpClientService,
		private ss: SingletonService,
		private user: UserService, private formBuilder: FormBuilder) {
		this.companyFormGroup = this.formBuilder.group({
			'company_name': ['', Validators.required],
			'social_Platform': ['', Validators.required]
		});
		this.editCompany = this.formBuilder.group({
			'companyId' : [''],
			'LinkedIn' : [''],
			'Instagram' : [''],
			'Facebook' : [''],
			'Twitter' : [''],
			'Youtube' : ['']
		});
	}

	ngOnInit() {
		this.getCompanyDetails();
	}

	openCompanyModal() {
		this.companyFormGroup.reset();
		this.getSocialMediaList();
		this.companyModal.open();
	}

	openCompanyEdit(details){
		console.log("-----details---------"+details.youtube);
		this.editingCompany = details.companyName;		
		this.editCompany.controls['companyId'].setValue(details.c_id);
		this.editCompany.controls['LinkedIn'].setValue(details.linkedin);
		this.editCompany.controls['Instagram'].setValue(details.instagram);
		this.editCompany.controls['Facebook'].setValue(details.facebook);
		this.editCompany.controls['Twitter'].setValue(details.twitter);
		this.editCompany.controls['Youtube'].setValue(details.youtube);
	this.editCompanyModal.open();
	}
	
	editCompanyModalClose(){
		this.editCompanyModal.close();
	}

	editCompanySubmit(value){
		this.httpClient.request('post', "company/edit-company", '', value).subscribe((res: any) => {
			if (res.status === 200) {
				if (res.error.text == "Edited successfully") {
					this.getCompanyDetails();
					this.editCompanyModal.close();
				}
			}
		})
	}

	AddCompanySubmit(value) {
		if (this.companyFormGroup.valid) {
			this.httpClient.request('post', "company/add-company", '', value).subscribe((res: any) => {
				if (res.status === 200) {
					if (res.error.text == "Company added successfully") {
						this.ss.statusMessage.showStatusMessage(true, 'Company added sucessfully');
						this.companyModal.close();
						this.getCompanyDetails();
						this.closePopup();
					}
					else if (res.error.text == "Company already exist") {
						this.ss.statusMessage.showStatusMessage(false, 'Company already exist', 3000, 'exist');
					}
				}
				else {
					this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
				}
			});
		}
	}

	getCompanyDetails() {
		
		this.httpClient.request('get', "company/get-companyList").subscribe((res: any) => {
			if (res.status === 200) {
				this.companyList = res.body;
			
				var merged = _.merge(_.keyBy(this.companyList, 'companyName'), _.keyBy(this.tempObj, 'companyName'));
				// var values = _.values(merged);
				// console.log(""= values);
				console.log("---keyvalue----" + JSON.stringify(this.tempObj));
			}
			else {
				this.ss.statusMessage.showStatusMessage(false, "Something went wrong cannot get company list");
			}
		});
	}

	getSocialMediaList() {
		this.httpClient.request('get', "company/get-socialMediaList").subscribe((res: any) => {
			if (res.status === 200) {
				this.socialMedia = res.body;
			}
			else {
				this.ss.statusMessage.showStatusMessage(false, "Something went wrong cannot get socialMedia list");
			}
		});
	}

	// close the popup
    closePopup() {
        console.log('closed modal pop up');
        this.companyModal.animationService.fadeOutUp(this.companyModal.overlay.nativeElement);
        clearInterval(this.companyModal.timer);
		this.companyModal.closeEvent.emit(this.companyModal.idd);

		this.companyFormGroup.reset();
    }

}
