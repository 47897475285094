 
import { AppRoutingModule } from './app-routing.module';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule,MAT_FORM_FIELD_DEFAULT_OPTIONS, MatSelectModule, MatTabsModule, MatCheckboxModule, MatButtonModule, MatTableModule, MatBadgeModule, MatDatepickerModule, MatNativeDateModule, MatInputModule, MatSliderModule, MatRadioButton, MatRadioModule, MatAutocompleteModule, MatBottomSheetModule, MatButtonToggleModule, MatCardModule, MatChipsModule, MatDialogModule, MatDividerModule, MatExpansionModule, MatGridListModule, MatListModule, MatMenuModule, MatPaginatorModule, MatProgressBarModule, MatProgressSpinnerModule, MatRippleModule, MatSidenavModule, MatSlideToggleModule, MatSnackBarModule, MatSortModule, MatStepperModule, MatToolbarModule, MatTooltipModule, MatTreeModule,  MAT_DATE_LOCALE } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { LoaderComponent } from './components/loader/loader.component';
import { HttpClientService } from './services/http-client.service';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { XhrProgressService } from './services/xhr-progress.service';
import { LodashPipe } from './pipes/lodash.pipe';
import { StatusMessageComponent } from './components/status-message/status-message.component';
import { ModalPopupModule } from './components/modal-popup/modal-popup.module';
import { AnimationsService } from './services/animations.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FileDownloadModule } from './directives/file-download/file-download.module';
import { WindowReferenceService } from './services/window-reference.service';
import { ChartsModule } from 'ng2-charts';
import { DatePipe, CommonModule } from '@angular/common';
import { NgxMatDrpModule } from 'ngx-mat-daterange-picker';
import { DialogBodyComponent } from './components/dialog-body/dialog-body.component';
import { FileDropDirective, FileSelectDirective} from 'ng2-file-upload';
import {SvgSymbolModule} from './directives/svg-symbol/SvgSymbolModule'
import { JwtModule } from '@auth0/angular-jwt';
import { LoginComponent } from './view-components/login/login.component';
import { UserService } from './services/user.service';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthInterceptor } from './services/auth-interceptor';
import { NotificationService } from './services/NotificationService';
import { EventsComponent } from './view-components/events/events.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { ViewCalendarComponent } from './view-components/view-calendar/view-calendar.component';
import {NgbModule, NgbModalModule} from '@ng-bootstrap/ng-bootstrap';
import { FlatpickrModule } from 'angularx-flatpickr';

import { ContextMenuModule } from 'ngx-contextmenu';
import { DashboardViewComponent } from './view-components/dashboard-view/dashboard-view.component';
import { CompaniesComponent } from './view-components/companies/companies.component';
import { FollowerComponent } from './view-components/follower/follower.component';
import { ViewReportsComponent } from './view-components/view-reports/view-reports.component';
import { NgxCopyToClipboardModule } from 'ngx-copy-to-clipboard';
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,   
    BreadcrumbsComponent,   
    LoaderComponent,
    ProgressBarComponent,
    LodashPipe,
    StatusMessageComponent,  
    DialogBodyComponent,   
    LoginComponent,
    EventsComponent,
    ViewCalendarComponent,
    DashboardViewComponent,
    CompaniesComponent,
    FollowerComponent,
    ViewReportsComponent
  ],
  imports: [  
    ContextMenuModule.forRoot({
      useBootstrap4: true
    }),
    
    CommonModule,
    FormsModule,
    NgbModalModule,
    FlatpickrModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    MatIconModule,
    BrowserAnimationsModule,
    RouterModule,
    AppRoutingModule,
    HttpClientModule,
    ModalPopupModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatTabsModule,
    MatButtonModule,
    FormsModule,
    FileDownloadModule,
    SvgSymbolModule,
    MatTableModule,
    MatBadgeModule,
    MatSliderModule,
    MatRadioModule,
    ChartsModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    NgxMatDrpModule,
    MatDialogModule,
    JwtModule,
    NgxCopyToClipboardModule
  ],
  providers: [HttpClientService, XhrProgressService, AnimationsService, WindowReferenceService,DatePipe,UserService,AuthGuardService, NotificationService, { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }],
  bootstrap: [AppComponent],
  entryComponents: [DialogBodyComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
                 
})
export class AppModule { }
