import { Component, OnInit, ViewChild } from '@angular/core';
import { SingletonService } from '../../services/singleton.service';
import * as _ from 'lodash';
import { HttpClientService } from 'src/app/services/http-client.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxDrpOptions, PresetItem, Range } from 'ngx-mat-daterange-picker';
import { DatePipe } from '@angular/common';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-dashboard-view',
  templateUrl: './dashboard-view.component.html',
  styleUrls: ['./dashboard-view.component.scss']
})
export class DashboardViewComponent implements OnInit {
	options: NgxDrpOptions;
	@ViewChild('pickerTwo', { static: false }) pickerTwo;
	companyFormGroup: FormGroup;
	range: any;
	overviewData: any;
	followersData: any;
	followersCount: any = {};
	pieChartData: { value: any; label: string; }[];
	twitterGraphData: {};
	facebookGraphData: {};
	igGraphData: {};
	linkedInGraphData: {};
	youtubeGraphData: {};
	facebookEventGraphData: {};
	twitterEventGraphData: {};
	igEventGraphData: {};
	youtubeEventGraphData: {};
	linkedInEventGraphData: {};
	today = new Date();
	asOnDate: any;
	totalCount: number;
	companyList: any = [];
	pieChartJson: any;
	displayPieChart: boolean = false;
	companyId: number;
	
	graphWidth = (window.innerWidth - 200);
	screenHeight = (screen.height - 300)/2;
	linkedInLineChartSettings = {
		"title": "LinkedIn",
		"valueType":"integer",
		width: this.graphWidth/4,
		height: this.screenHeight,
		fontSize: {
			label: 13,
			description: 17,
			legend: 13,
			title: 38
		},
		labelType: "date",
	};
	twitterLineChartSettings = {
		"title": "Twitter",
		"valueType":"integer",
		width: this.graphWidth/4,
		height: this.screenHeight,
		fontSize: {
			label: 13,
			title: 38
		},
		labelType: "date",
	};
	facebookLineChartSettings = {
		"title": "Facebook",
		"valueType":"integer",
		width: this.graphWidth/4,
		height: this.screenHeight,
		fontSize: {
			label: 13,
			description: 17,
			legend: 13,
			title: 38
		},
		labelType: "date",
	};
	igLineChartSettings = {
		"title": "Instagram",
		"valueType":"integer",
		width: this.graphWidth/4,
		height: this.screenHeight,
		fontSize: {
			label: 13,
			description: 17,
			legend: 13,
			title: 38
		},
		labelType: "date",
	};
	youtubeLineChartSettings = {
		"title": "Youtube",
		"valueType":"integer",
		width: this.graphWidth/4,
		height: this.screenHeight,
		fontSize: {
			label: 13,
			description: 17,
			legend: 13,
			title: 38
		},
		labelType: "date",
	};

	
	constructor(private fb: FormBuilder, private httpClient: HttpClientService,
		private ss: SingletonService,
		private user: UserService, private formBuilder: FormBuilder,
		public datepipe: DatePipe) {
		this.companyFormGroup = this.formBuilder.group({
			'company_id': ['', Validators.required]
		})
	}

	ngOnInit() {
		const today = new Date();
		const todayTo = new Date();
		let todayFrom = new Date();
		todayFrom = new Date(todayFrom.setDate(todayFrom.getDate()));
		const fromMin = new Date(today.getFullYear(), today.getMonth() - 2, 1);
		const fromMax = new Date(today.getFullYear(), today.getMonth() + 1, 0);
		const toMin = new Date(today.getFullYear(), today.getMonth() - 1, 1);
		const toMax = new Date(today.getFullYear(), today.getMonth() + 2, 0);
		this.ss.setupPresets();
		this.options = {
			presets: this.ss.presets,
			format: 'mediumDate',
			range: { fromDate: new Date(today.setDate(today.getDate() - 7)), toDate: new Date(todayTo.setDate(todayTo.getDate())) },
			applyLabel: 'Submit',
			// excludeWeekends:true,
			fromMinMax: { fromDate: fromMin, toDate: todayFrom },
			toMinMax: { fromDate: fromMin, toDate: todayFrom }
		};
	}

	ngAfterViewInit() {
		this.getCompanyDetails();
	}

	updateRange(range: Range) {
		this.range = range;
		this.range.fromDate = this.datepipe.transform(this.range.fromDate, 'yyyy-MM-dd');
		this.range.toDate = this.datepipe.transform(this.range.toDate, 'yyyy-MM-dd');
		this.getFollowerCountByFilter();
		this.getEventCountByFilter();
		// this.asOnDate = this.range.toDate;
	}

	reset() {
		const today = new Date();
		const currMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
		const currMonthEnd = new Date();
		this.pickerTwo.resetDates({ fromDate: currMonthStart, toDate: currMonthEnd });
	}

	getCompanyDetails() {
		this.httpClient.request('get', "company/get-companyDropdownList").subscribe((res: any) => {
			if (res.status === 200) {
				this.companyList = res.body;
				if (this.companyList.length > 0) {
					this.companyFormGroup.controls['company_id'].setValue(this.companyList[0].c_id);
					this.companyId = this.companyList[0].c_id;
					this.getFollowersCount(this.companyList[0].c_id);
				}
			}
			else {
				this.ss.statusMessage.showStatusMessage(false, "Something went wrong cannot get company list");
			}
		});
	}

	// getOverviewData() {
	// 	this.httpClient.request('get', "overview/get-overview-data").subscribe((res: any) => {
	// 		if (res.status === 200) {

	// 		}
	// 		else {
	// 			this.ss.statusMessage.showStatusMessage(false, "Something went wrong cannot get overview data");
	// 		}
	// 	});
	// }

	getFollowersCount(companyId) {
		this.companyId = companyId;
		this.httpClient.request('get', "followers/get-company-followersCount", 'companyId=' + companyId).subscribe((res: any) => {
			if (res.status === 200) {
				this.pieChartJson = {};
				this.pieChartData = [];
				this.followersCount = {};
				this.displayPieChart = false;
				this.followersData = res.body;
				this.totalCount = 0;
				if (this.followersData.length > 0) {
					this.asOnDate = this.followersData[0].created_date;
					for (let i = 0; i < this.followersData.length; i++) {
						if (this.followersData[i].social_media_id == 1) {
							this.followersCount["LinkedIn"] = this.followersData[i].followers_count;
						} else if (this.followersData[i].social_media_id == 2) {
							this.followersCount["Twitter"] = this.followersData[i].followers_count;
						} else if (this.followersData[i].social_media_id == 3) {
							this.followersCount["Facebook"] = this.followersData[i].followers_count;
						} else if (this.followersData[i].social_media_id == 4) {
							this.followersCount["Instagram"] = this.followersData[i].followers_count;
						} else if (this.followersData[i].social_media_id == 5) {
							this.followersCount["Youtube"] = this.followersData[i].followers_count;
						}
					}

					let keys = Object.keys(this.followersCount);
					let values = Object.values(this.followersCount);
					this.totalCount = _.sum(values);
					this.pieChartData = values.map((v, i) =>
						({ label: keys[i], value: v })
					)
					//console.log("------------pie chart data-----------" + JSON.stringify(this.pieChartData));
					this.pieChartJson = {
							// title: "Title",
							// description: "Desscription",
							// width: 500,
							height: this.screenHeight,
							dataValuesUnits: "%",
							fontSize: {
								label: 13,
								description: 14,
								legend: 13,
								title: 38
							},
							legend: [
								{
									value: "Year 2019",
									thumbColor: "#000"
								}, {
									value: "Year 2018",
									thumbColor: "#333"
								}
							],
						
						data: this.pieChartData
					}
					this.displayPieChart = true;
					// console.log("------------pie chart data-----------" + JSON.stringify(this.pieChartJson));
				}
				// console.log("------------pie chart data-----------" + JSON.stringify(this.pieChartJson));
			}
			else {
				this.ss.statusMessage.showStatusMessage(false, "Something went wrong cannot get overview data");
			}
		});
		this.getFollowerCountByFilter();
		this.getEventCountByFilter();
	}

	getFollowerCountByFilter() {
		this.range.fromDate = this.datepipe.transform(this.range.fromDate, 'yyyy-MM-dd');
		this.range.toDate = this.datepipe.transform(this.range.toDate, 'yyyy-MM-dd');
		this.range.companyId = this.companyId;
		this.facebookGraphData = [];
		this.twitterGraphData = [];
		this.igGraphData = [];
		this.linkedInGraphData = [];
		this.youtubeGraphData = [];
		// console.log("----------this.linkedInLineChartSettings--------" + JSON.stringify(this.linkedInLineChartSettings));

		this.httpClient.request('get', "followers/get-followersCount-by-filter", 'range=' + JSON.stringify(this.range)).subscribe((res: any) => {
			if (res.status === 200) {
				let followersData = res.body;
				let LinkedIn = _.filter(followersData, { 'social_media_id': 1 });
				let twitter = _.filter(followersData, { 'social_media_id': 2 });
				let facebook = _.filter(followersData, { 'social_media_id': 3 });
				let Instagram = _.filter(followersData, { 'social_media_id': 4 });
				let Youtube = _.filter(followersData, { 'social_media_id': 5 });

				if (LinkedIn.length > 0) {
					this.linkedInGraphData = this.formGraphData(LinkedIn);
					this.linkedInLineChartSettings['data'] = this.linkedInGraphData;
					this.linkedInLineChartSettings = { ...this.linkedInLineChartSettings}
				}
				 console.log("----------this.LinkedInGraphData--------" + JSON.stringify(this.linkedInGraphData));
				if (twitter.length > 0) {
					this.twitterGraphData = this.formGraphData(twitter);
					this.twitterLineChartSettings['data'] = this.twitterGraphData;
					this.twitterLineChartSettings = {...this.twitterLineChartSettings};

				}
				 console.log("----------this.twitterGraphData--------" + JSON.stringify(this.twitterGraphData));


				if (facebook.length > 0) {
					this.facebookGraphData = this.formGraphData(facebook);
					this.facebookLineChartSettings['data'] = this.facebookGraphData;
					this.facebookLineChartSettings = {...this.facebookLineChartSettings};
				}
				 console.log("----------this.facebookGraphData--------" + JSON.stringify(this.facebookGraphData));

				if (Instagram.length > 0) {
					this.igGraphData = this.formGraphData(Instagram);
					this.igLineChartSettings['data'] = this.igGraphData;
					this.igLineChartSettings = {...this.igLineChartSettings};
				}
				 console.log("----------this.InstagramGraphData--------" + JSON.stringify(this.igGraphData));
				if (Youtube.length > 0) {
					this.youtubeGraphData = this.formGraphData(Youtube);
					this.youtubeLineChartSettings['data'] = this.youtubeGraphData;
					this.youtubeLineChartSettings = {...this.youtubeLineChartSettings};
				}
				console.log("----------this.youtubeGraphData--------" + JSON.stringify(this.youtubeGraphData));
				
			}
			else {
				this.ss.statusMessage.showStatusMessage(false, "Something went wrong cannot  getFollowerCountByFilter");
			}
		});
	}

	getEventCountByFilter() {
		this.range.fromDate = this.datepipe.transform(this.range.fromDate, 'yyyy-MM-dd');
		this.range.toDate = this.datepipe.transform(this.range.toDate, 'yyyy-MM-dd');
		this.range.companyId = this.companyId;
		this.facebookEventGraphData = [];
		this.twitterEventGraphData = [];
		this.igEventGraphData = [];
		this.linkedInEventGraphData = [];
		this.youtubeEventGraphData = [];

		this.httpClient.request('get', "overview/get-eventssCount-by-filter", 'range=' + JSON.stringify(this.range)).subscribe((res: any) => {
			if (res.status === 200) {
				let followersData = res.body;
				if (followersData.length > 0) {
					let LinkedIn = _.filter(followersData, { 'social_media_id': 1 });
					let twitter = _.filter(followersData, { 'social_media_id': 2 });
					let facebook = _.filter(followersData, { 'social_media_id': 3 });
					let Instagram = _.filter(followersData, { 'social_media_id': 4 });
					let Youtube = _.filter(followersData, { 'social_media_id': 5 }); 


					if (LinkedIn.length > 0) {
						this.linkedInEventGraphData = this.formGraphData(LinkedIn);
						this.linkedInLineChartSettings['dataEvents'] = this.linkedInEventGraphData;
						this.linkedInLineChartSettings = { ...this.linkedInLineChartSettings}
					}
					console.log("----------this.linkedInEventGraphData--------" + JSON.stringify(this.linkedInEventGraphData));

					if (twitter.length > 0) {
						this.twitterEventGraphData = this.formGraphData(twitter);
						this.twitterLineChartSettings['dataEvents'] = this.twitterEventGraphData;
						this.twitterLineChartSettings = {...this.twitterLineChartSettings};
					}
					console.log("----------this.twitterEventGraphData--------" + JSON.stringify(this.twitterEventGraphData));


					if (facebook.length > 0) {
						this.facebookEventGraphData = this.formGraphData(facebook);
						this.facebookLineChartSettings['dataEvents'] = this.facebookEventGraphData;
						this.facebookLineChartSettings = {...this.facebookLineChartSettings};
					}
					console.log("----------this.facebookEventGraphData--------" + JSON.stringify(this.facebookEventGraphData));


					if (Instagram.length > 0) {
						this.igEventGraphData = this.formGraphData(Instagram);
						this.igLineChartSettings['dataEvents'] = this.igEventGraphData;
						this.igLineChartSettings = {...this.igLineChartSettings};
					}
					console.log("----------this.igEventGraphData--------" + JSON.stringify(this.igEventGraphData));

					if (Youtube.length > 0) {
						this.youtubeEventGraphData = this.formGraphData(Youtube);
						this.youtubeLineChartSettings['dataEvents'] = this.youtubeEventGraphData;
						this.youtubeLineChartSettings = {...this.youtubeLineChartSettings};
					}
					console.log("----------this.youtubeEventGraphData--------" + JSON.stringify(this.linkedInLineChartSettings));
					
					
				}

			}
			else {
				this.ss.statusMessage.showStatusMessage(false, "Something went wrong cannot  getFollowerCountByFilter");
			}
		});
					this.linkedInLineChartSettings['data'] = this.linkedInGraphData;
					this.linkedInLineChartSettings['dataEvents'] = this.linkedInEventGraphData;
					this.linkedInLineChartSettings = { ...this.linkedInLineChartSettings}

					this.igLineChartSettings['data'] = this.igGraphData;
					this.igLineChartSettings['dataEvents'] = this.igEventGraphData;
					this.igLineChartSettings = {...this.igLineChartSettings};

					this.facebookLineChartSettings['data'] = this.facebookGraphData;
					this.facebookLineChartSettings['dataEvents'] = this.facebookEventGraphData;
					this.facebookLineChartSettings = {...this.facebookLineChartSettings};

					this.twitterLineChartSettings['data'] = this.twitterGraphData;
					this.twitterLineChartSettings['dataEvents'] = this.twitterEventGraphData;
					this.twitterLineChartSettings = {...this.twitterLineChartSettings};
					
					this.youtubeLineChartSettings['data'] = this.youtubeGraphData;
					this.youtubeLineChartSettings['dataEvents'] = this.youtubeEventGraphData;
					this.youtubeLineChartSettings = {...this.youtubeLineChartSettings};
	}

	formGraphData(smData) {
		let twDates = _.map(smData, _.property('createdDate'));
		let twFC = _.map(smData, _.property('count'));
		return twFC.map((v, i) =>
			({ label: twDates[i], value: v })
		)
	}
}