import { Component, ChangeDetectionStrategy, ViewChild, TemplateRef, OnInit } from '@angular/core';
import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours } from 'date-fns';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView, CalendarMonthViewBeforeRenderEvent } from 'angular-calendar';
import { ModalPopupComponent } from 'src/app/components/modal-popup/modal-popup.component';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { HttpClientService } from 'src/app/services/http-client.service';
import { SingletonService } from 'src/app/services/singleton.service';
import { DatePipe } from '@angular/common';

const colors: any = {
	red: {
		primary: '#ad2121',
		secondary: '#FAE3E3'
	},
	blue: {
		primary: '#1e90ff',
		secondary: '#D1E8FF'
	},
	yellow: {
		primary: '#e3bc08',
		secondary: '#FDF1BA'
	}
};

@Component({
	selector: 'app-view-calendar',
	templateUrl: './view-calendar.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./view-calendar.component.scss']
})
export class ViewCalendarComponent implements OnInit {
	@ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
	@ViewChild('eventFormModal', { static: false }) eventFormModal: ModalPopupComponent;
	@ViewChild('eventsInfoModal',  {static: false}) eventsInfoModal: ModalPopupComponent;
	view: CalendarView = CalendarView.Month;
	CalendarView = CalendarView;
	viewDate: Date = new Date();
	eventFormGroup: FormGroup;
	companyList: any;
	eventTypes: any;
	companyFormGroup: FormGroup;
	// modalData: {
	// 	action: string;
	// 	event: CalendarEvent;
	// };

	// actions: CalendarEventAction[] = [
	// 	{
	// 		label: '<i class="fa fa-fw fa-pencil"></i>',
	// 		a11yLabel: 'Edit',
	// 		onClick: ({ event }: { event: CalendarEvent }): void => {
	// 			this.handleEvent('Edited', event);
	// 		}
	// 	},
	// 	{
	// 		label: '<i class="fa fa-fw fa-times"></i>',
	// 		a11yLabel: 'Delete',
	// 		onClick: ({ event }: { event: CalendarEvent }): void => {
	// 			this.events = this.events.filter(iEvent => iEvent !== event);
	// 			this.handleEvent('Deleted', event);
	// 		}
	// 	}
	// ];

	refresh: Subject<any> = new Subject();

	events: CalendarEvent[] = [
		// {
		// 	start: subDays(startOfDay(new Date()), 1),
		// 	end: addDays(new Date(), 1),
		// 	title: 'A 3 day event',
		// 	color: colors.red
		// },
		// {
		// 	start: startOfDay(new Date()),
		// 	title: 'An event with no end date',
		// 	color: colors.yellow
		// }
		// {
		//   start: subDays(endOfMonth(new Date()), 3),
		//   end: addDays(endOfMonth(new Date()), 3),
		//   title: 'A long event that spans 2 months',
		//   color: colors.blue,
		//   allDay: true
		// },
		// {
		//   start: addHours(startOfDay(new Date()), 2),
		//   end: addHours(new Date(), 2),
		//   title: 'A draggable and resizable event',
		//   color: colors.yellow,
		//   actions: this.actions,
		//   resizable: {
		//     beforeStart: true,
		//     afterEnd: true
		//   },
		//   draggable: true
		// }
	];

	activeDayIsOpen: boolean = true;
	completedEventCount: any = 0;
	scheduledEventCount: any = 0;
	jpPlannedCount: number;
	holidayPlannedCount: number;
	tpPlannedCount: number;
	techImagePlannedCount: number;
	techVideoPlannedCount: number;
	eePlannedCount: number;
	biPlannedCount: number;
	bvPlannedCount: number;
	othersPlannedCount: number;
	jpComplCount: number;
	holidayComplCount: number;
	tpComplCount: number;
	techImageComplCount: number;
	techVideoComplCount: number;
	eeComplCount: number;
	biComplCount: number;
	bvComplCount: number;
	othersComplCount: number;
	showMssg: boolean = false;

	constructor(private modal: NgbModal, private httpClient: HttpClientService,
		private ss: SingletonService, public datepipe: DatePipe,
		private formBuilder: FormBuilder) {
		this.eventFormGroup = this.formBuilder.group({
			'companyId': ['', Validators.required],
			'event_id': ['', Validators.required],
			'start_date': ['', Validators.required],
			'end_date': ['', Validators.required]
		});
		this.companyFormGroup = this.formBuilder.group({
			'company_id': ['', Validators.required]
		})

		this.onSuccess = this.onSuccess.bind(this);
		this.onError = this.onError.bind(this);
	}

	copiedText = '';
	onSuccess(e) {
		this.copiedText = e.text;
		this.showMssg = true;
		setTimeout(() => {
			this.showMssg = false;
		this.refresh.next();

		}, 2000);
		
		this.refresh.next();
	  }
	
	  onError(e) {
		this.copiedText = 'Error trying to copy your text';
	  }

	ngOnInit() {
		this.getCompanyDetails();
	}

	getCompanyDetails() {
		this.httpClient.request('get', "company/get-companyDropdownList").subscribe((res: any) => {
			if (res.status === 200) {
				this.companyList = res.body;
				if (this.companyList.length > 0) {
					this.companyFormGroup.controls['company_id'].setValue(this.companyList[0].c_id);
					this.getScheduledEventsList();
				}
			}
			else {
				this.ss.statusMessage.showStatusMessage(false, "Something went wrong cannot get company list");
			}
		});
	}

	openAddEvent(value) {
		this.eventFormGroup.controls['start_date'].setValue(value["item"]);
		this.eventFormGroup.controls['end_date'].setValue(value["item"]);
		this.getEventTypes();
		this.eventFormModal.open();
		this.eventFormGroup.controls['companyId'].setValue(this.companyFormGroup.controls['company_id'].value);
	}

	addEventSubmit(value) {
		value.start_date = this.datepipe.transform(value.start_date, 'yyyy-MM-dd');
		value.end_date = this.datepipe.transform(value.end_date, 'yyyy-MM-dd');
		if (this.eventFormGroup.valid) {
			this.httpClient.request('post', "schedularstatus/schedule-events", '', value).subscribe((res: any) => {
				if (res.status === 200) {
					if (res.error.text == "Schedule added successfully") {
						this.getScheduledEventsList();
						this.ss.statusMessage.showStatusMessage(true, "Scheduled successfully");
						this.closePopup();
					}
					else {
						this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
					}
				}
			})

		}
	}

	getScheduledEventsList() {
		this.getEachPlannedEventCount();
		this.scheduledEventCount = 0;
		let compId = this.companyFormGroup.controls['company_id'].value;
		let monthDate = this.datepipe.transform(this.viewDate, 'yyyy-MM-dd');
		this.httpClient.request('get', "schedularstatus/get-scheduledEvents?companyId=" + compId + "&monthDate="+monthDate).subscribe((res: any) => {
			if (res.status === 200) {
				//res.body[i].colour,
				this.events = [];
				for (let i = 0; i < res.body.length; i++) {
					this.events = [
						...this.events,
						{
							id: 1,
							title: "P: "+res.body[i].e_type_name,
							start: new Date(res.body[i].start_date),
							end: new Date(res.body[i].end_date),
							color: res.body[i].color,
							// cssClass : "dispBorder",							
							draggable: true,
							resizable: {
								beforeStart: true,
								afterEnd: true
							}
						}
					];
					if ( new Date(this.datepipe.transform(res.body[i].firstDate, 'yyyy-MM-dd')).getTime() <= new Date(this.datepipe.transform(res.body[i].start_date, 'yyyy-MM-dd')).getTime() &&  new Date(this.datepipe.transform(res.body[i].lastDay, 'yyyy-MM-dd')).getTime() >= new Date(this.datepipe.transform(res.body[i].start_date, 'yyyy-MM-dd')).getTime()) {
						this.setSchEventCount();
					}
				}
				this.refresh.next();
				this.getCompletedSchList();
			}
			else {
				this.ss.statusMessage.showStatusMessage(false, "Something went wrong cannot get Scheduled events list");
			}
		});
	}

	getCompletedSchList() {
		this.completedEventCount = 0;
		let compId = this.companyFormGroup.controls['company_id'].value;
		let monthDate = this.datepipe.transform(this.viewDate, 'yyyy-MM-dd');
		this.httpClient.request('get', "schedularstatus/get-completedScheduledEvents?companyId=" + compId + "&monthDate="+monthDate).subscribe((res: any) => {
			if (res.status === 200) {
				for (let i = 0; i < res.body.length; i++) {
					this.events = [
						...this.events,
						{
							id:2,
							title: "A: "+res.body[i].e_type_name,
							start: new Date(res.body[i].createdDate),
							// end: new Date(res.body[i].end_date),
							color: res.body[i].color,
							// cssClass : "dispBorder",							
							draggable: true,
							resizable: {
								beforeStart: true,
								afterEnd: true
							}
						}
					];
					if ( new Date(this.datepipe.transform(res.body[i].firstDate, 'yyyy-MM-dd')).getTime() <= new Date(this.datepipe.transform(res.body[i].createdDate, 'yyyy-MM-dd')).getTime() &&  new Date(this.datepipe.transform(res.body[i].lastDay, 'yyyy-MM-dd')).getTime() >= new Date(this.datepipe.transform(res.body[i].createdDate, 'yyyy-MM-dd')).getTime()) {
						this.setComplEventCount();
					}
				}
				this.refresh.next();
			}
			else {

			}
		});
	}

	setComplEventCount(){
		this.completedEventCount++;
	}

	setSchEventCount(){
		this.scheduledEventCount++;
	}

	closePopup() {
		this.eventFormModal.animationService.fadeOutUp(this.eventFormModal.overlay.nativeElement);
		clearInterval(this.eventFormModal.timer);
		this.eventFormModal.closeEvent.emit(this.eventFormModal.idd);
		this.eventFormGroup.reset();
	}

	getEventTypes() {
		this.httpClient.request('get', "company/getEventTypes").subscribe((res: any) => {
			if (res.status === 200) {
				this.eventTypes = res.body;
			}
			else {
				this.ss.statusMessage.showStatusMessage(false, "Something went wrong cannot get event types");
			}
		});
	}



	setView(view: CalendarView) {
		this.view = view;
	}

	closeOpenMonthViewDay() {
		this.activeDayIsOpen = false;
		// this.getCompletedSchList();
		this.updateSchEventCount();
		// this.updateCompletedEventCount();
		
	}

	updateSchEventCount(){
		this.getEachPlannedEventCount();
		this.scheduledEventCount = 0;
		let compId = this.companyFormGroup.controls['company_id'].value;
		let monthDate = this.datepipe.transform(this.viewDate, 'yyyy-MM-dd');
		this.httpClient.request('get', "schedularstatus/get-scheduledEvents?companyId=" + compId + "&monthDate="+monthDate).subscribe((res: any) => {
			if (res.status === 200) {
				for (let i = 0; i < res.body.length; i++) {			 
					if ( new Date(this.datepipe.transform(res.body[i].firstDate, 'yyyy-MM-dd')).getTime() <= new Date(this.datepipe.transform(res.body[i].start_date, 'yyyy-MM-dd')).getTime() &&  new Date(this.datepipe.transform(res.body[i].lastDay, 'yyyy-MM-dd')).getTime() >= new Date(this.datepipe.transform(res.body[i].start_date, 'yyyy-MM-dd')).getTime()) {
						this.setSchEventCount();
					}
				}
				this.refresh.next();
			}
		})
		this.updateCompletedEventCount();
		
	}

	openEventsInfo(){
		this.eventsInfoModal.open();
	}

	getEachPlannedEventCount(){
		this.jpPlannedCount = 0;
		this.holidayPlannedCount = 0;
		this.tpPlannedCount = 0;
		this.techImagePlannedCount = 0;
		this.techVideoPlannedCount = 0;
		this.eePlannedCount = 0;
		this.biPlannedCount = 0;
		this.bvPlannedCount = 0;
		this.othersPlannedCount = 0;
		let compId = this.companyFormGroup.controls['company_id'].value;
		let month = this.datepipe.transform(this.viewDate, 'MM');
		let year = this.datepipe.transform(this.viewDate, 'yyyy');

		this.httpClient.request('get', "schedularstatus/get-eachScheduledEventsCount?companyId=" + compId + "&month="+month + "&year="+year).subscribe((res: any) => {
			if (res.status === 200) {
				for (let i = 0; i < res.body.length; i++) {
					// console.log("----type id------"+ res.body[i].e_type_id);
					if( res.body[i].e_type_id == 1){
						this.jpPlannedCount++;
					}
					else if(res.body[i].e_type_id == 2){
						this.holidayPlannedCount++;
					}

					else if(res.body[i].e_type_id == 3){
						this.tpPlannedCount++;
					}
					else if(res.body[i].e_type_id == 4){
						this.techImagePlannedCount++;
					}
					else if(res.body[i].e_type_id == 5){
						this.techVideoPlannedCount++;
					}
					else if(res.body[i].e_type_id == 6){
						this.eePlannedCount++;
					}
					else if(res.body[i].e_type_id == 7){
						this.biPlannedCount++;
					}
					else if(res.body[i].e_type_id == 8){
						this.bvPlannedCount++;
					}
					else{
						this.othersPlannedCount++
					}

				}
			}
		})

		this.getEachCompletedEventCount();

		
	}

	getEachCompletedEventCount(){
		this.jpComplCount = 0;
		this.holidayComplCount = 0;
		this.tpComplCount = 0;
		this.techImageComplCount = 0;
		this.techVideoComplCount = 0;
		this.eeComplCount = 0;
		this.biComplCount = 0;
		this.bvComplCount = 0;
		this.othersComplCount = 0;
		let compId = this.companyFormGroup.controls['company_id'].value;
		let month = this.datepipe.transform(this.viewDate, 'MM');
		let year = this.datepipe.transform(this.viewDate, 'yyyy');

		this.httpClient.request('get', "schedularstatus/get-eachCompletedEventsCount?companyId=" + compId + "&month="+month + "&year="+year).subscribe((res: any) => {
			if (res.status === 200) {
				for (let i = 0; i < res.body.length; i++) {
					// console.log("----type id------"+ res.body[i].e_type_id);
					if( res.body[i].e_type_id == 1){
						this.jpComplCount++;
					}
					else if(res.body[i].e_type_id == 2){
						this.holidayComplCount++;
					}

					else if(res.body[i].e_type_id == 3){
						this.tpComplCount++;
					}
					else if(res.body[i].e_type_id == 4){
						this.techImageComplCount++;
					}
					else if(res.body[i].e_type_id == 5){
						this.techVideoComplCount++;
					}
					else if(res.body[i].e_type_id == 6){
						this.eeComplCount++;
					}
					else if(res.body[i].e_type_id == 7){
						this.biComplCount++;
					}
					else if(res.body[i].e_type_id == 8){
						this.bvComplCount++;
					}
					else{
						this.othersComplCount++;
					}

				}
			}
		})

		
	}

	updateCompletedEventCount(){
		this.completedEventCount = 0;
		let compId = this.companyFormGroup.controls['company_id'].value;
		let monthDate = this.datepipe.transform(this.viewDate, 'yyyy-MM-dd');
		this.httpClient.request('get', "schedularstatus/get-completedScheduledEvents?companyId=" + compId + "&monthDate="+monthDate).subscribe((res: any) => {
			if (res.status === 200) {				
				for (let i = 0; i < res.body.length; i++) {			 
					if ( new Date(this.datepipe.transform(res.body[i].firstDate, 'yyyy-MM-dd')).getTime() <= new Date(this.datepipe.transform(res.body[i].createdDate, 'yyyy-MM-dd')).getTime() &&  new Date(this.datepipe.transform(res.body[i].lastDay, 'yyyy-MM-dd')).getTime() >= new Date(this.datepipe.transform(res.body[i].createdDate, 'yyyy-MM-dd')).getTime()) {
						this.setComplEventCount();
					}
				}
				this.refresh.next();
			}
		})
	}

	beforeMonthViewRender(renderEvent: CalendarMonthViewBeforeRenderEvent): void {
		// this.getCompletedSchList();
		// renderEvent.body.forEach(day => {
		// 	month = day.date.getMonth();
		// })
		// alert("------------------month----------------"+month);
		// this.getCompletedSchList();	
		
	}

}
