import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './view-components/login/login.component';
import { AuthGuardService } from './services/auth-guard.service';
import { EventsComponent } from './view-components/events/events.component';
import { ViewCalendarComponent } from './view-components/view-calendar/view-calendar.component';
import { DashboardViewComponent } from './view-components/dashboard-view/dashboard-view.component';
import { CompaniesComponent } from './view-components/companies/companies.component';
import { FollowerComponent } from './view-components/follower/follower.component';
import { ViewReportsComponent } from './view-components/view-reports/view-reports.component';

/* data: {
    breadcrumb: { // when breadcrumb data is an object
        queryParamKey: '' // possible values are string with queryparam name (eg: 'cpu_id') or array of query params (eg: ['edge_node_id','cpu_id']) or string with value 'all' . based on this all queryparams from current route will be available on the breadcrumb link,
            , addQueryParamInUrl: true // if this key is set to true then only the query params are added in the link of the breadcrumb segment
    }
} */

const routes: Routes = [

    { path: 'reports',canActivate:[AuthGuardService], data: { breadcrumb: 'Reports' }, component: ViewReportsComponent },
    { path: 'events',canActivate:[AuthGuardService], data: { breadcrumb: 'Events' }, component: EventsComponent},
    { path: 'calendar',canActivate:[AuthGuardService], data: { breadcrumb: 'Calendar'}, component: ViewCalendarComponent},
    { path: 'followersList',canActivate:[AuthGuardService], data:{breadcrumb:'followers'}, component: FollowerComponent},
    { path: 'companies',canActivate:[AuthGuardService], data: {breadcrumb: 'companies'}, component: CompaniesComponent },
    { path: 'dashboard',canActivate:[AuthGuardService], data:{ breadcrumb: 'DashboardView'}, component: DashboardViewComponent},
    { path: 'login', data: { breadcrumb: 'Login' }, component: LoginComponent },
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: '**', redirectTo: 'login', pathMatch: 'full' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
// http://localhost:4200/logical-view/edge-node/edge-node-details/cpu-details?ip=192.168.26.16&edge_node_id=192.168.26.16&view=Frames&cpu_id=1&&gpu_id=1