import { SingletonService } from './../../services/singleton.service';
import { Component, OnInit, HostListener, HostBinding, Input } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { UserService } from 'src/app/services/user.service';

@Component({
	selector: 'dbd-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss'],
	animations: [
		trigger('toggleMenu', [
			state(
				'true',
				style({
					'width': '225px'
				})
			),
			state(
				'false',
				style({
					'width': '100px'
				})
			),
			transition('*<=>*', [animate(
				'200ms'
			)])
		])
	]
})
export class SidebarComponent implements OnInit {

	overView = '../../../assets/images/Overview.png';
	addEmployee = '../../../assets/images/Add Employee.png';
	employeeList = '../../../assets/images/Employee List.png';
	project = '../../../assets/images/Projects.png';
	mapping = '../../../assets/images/MAPPING.png';
	report = '../../../assets/images/Report.png';

	static selectedIndex: number;
	userPriviliges: any;
	items: { text: string; link: string; icon: string; selectedIndex: number; }[];
	constructor(
		private ss: SingletonService,
		private user: UserService
	) { }



	isMenuOpen: boolean = false;

	@Input() selectedIndex: number = 0;
	select(index: number) {
		this.selectedIndex = index;
	}

	ngOnInit() {
		this.ss.isMenuOpenSubject.next(this.isMenuOpen);

		this.ss.setactive.subscribe(res => {
			this.selectedIndex = res
		}, err => {
			console.log(err);
		});

	}

	ngAfterViewInit() {
		if (this.user.validateSession()) {
			this.items = [
				{ text: 'Overview', link: '/dashboard', icon: this.overView, selectedIndex: 0 },
				{ text: 'Companies', link: '/companies', icon: this.project, selectedIndex: 1 },
				{ text: 'Events', link: '/events', icon: this.mapping, selectedIndex: 2 },
				{ text: 'Followers', link: '/followersList', icon: this.mapping, selectedIndex: 3 },
				{ text: 'Calendar', link: '/calendar', icon: this.mapping, selectedIndex: 4},
				{ text: 'Report', link:'/reports', icon: this.mapping, selectedIndex: 5}				
			]
		}
	}

	// @HostListener('click', ['$event', '$event.target'])
	onClick(e, target) {
		this.isMenuOpen = !this.isMenuOpen;
		this.ss.isMenuOpenSubject.next(this.isMenuOpen);
	}

	@HostBinding('@toggleMenu') get toggleMenu() {
		return this.isMenuOpen;
	}
	// @HostBinding('class.menu-open') get open() { return this.isMenuOpen; }
}
