import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgSymbolDirective } from './svg-symbol.directive';
@NgModule({
  declarations: [SvgSymbolDirective],
  imports: [
    CommonModule
  ],
  exports: [SvgSymbolDirective]
})
export class SvgSymbolModule {
}
