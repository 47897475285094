import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { HttpClientService } from 'src/app/services/http-client.service';
import { SingletonService } from 'src/app/services/singleton.service';
import { UserService } from 'src/app/services/user.service';
import { DatePipe } from '@angular/common';
import * as _ from 'lodash';

@Component({
	selector: 'app-view-reports',
	templateUrl: './view-reports.component.html',
	styleUrls: ['./view-reports.component.scss']
})
export class ViewReportsComponent implements OnInit {

	companyFormGroup: FormGroup;
	companyList: any = [];
	companyId: number;
	range: any;
	twitterGraphData: {};
	facebookGraphData: {};
	igGraphData: {};
	linkedInGraphData: {};
	overAllFolrGraphData: {};
	monthsArray = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec"
	]
	graphWidth = (window.innerWidth - 200);
	screenHeight = (screen.height - 300)/2;
	linkedInLineChartSettings = {
		"title": "LinkedIn",
		"valueType":"integer",
		width: this.graphWidth/2,
		height: this.screenHeight,
		fontSize: {
			label: 13,
			description: 17,
			legend: 13,
			title: 38
		},
		labelType: "discrete",
	};
	twitterLineChartSettings = {
		"title": "Twitter",
		"valueType":"integer",
		width: this.graphWidth/2,
		height: this.screenHeight,
		fontSize: {
			label: 13,
			title: 38
		},
		labelType: "discrete",
	};
	facebookLineChartSettings = {
		"title": "Facebook",
		"valueType":"integer",
		width: this.graphWidth/2,
		height: this.screenHeight,
		fontSize: {
			label: 13,
			description: 17,
			legend: 13,
			title: 38
		},
		labelType: "discrete",
	};
	igLineChartSettings = {
		"title": "Instagram",
		"valueType":"integer",
		width: this.graphWidth/2,
		height: this.screenHeight,
		fontSize: {
			label: 13,
			description: 17,
			legend: 13,
			title: 38
		},
		labelType: "discrete",
	};
	overAllLineChartSettings = {
		"title": "Overall",
		"valueType":"integer",
		width: this.graphWidth,
		height: this.screenHeight,
		fontSize: {
			label: 13,
			description: 17,
			legend: 13,
			title: 38
		},
		labelType: "discrete",
	};

	constructor(private fb: FormBuilder, private httpClient: HttpClientService,
		private ss: SingletonService,
		private user: UserService, private formBuilder: FormBuilder,
		public datepipe: DatePipe) {
		this.companyFormGroup = this.formBuilder.group({
			'company_id': ['', Validators.required]
		})
	}

	ngOnInit() {
	}

	ngAfterViewInit() {
		this.getCompanyDetails();
	}

	getCompanyDetails() {
		this.httpClient.request('get', "company/get-companyDropdownList").subscribe((res: any) => {
			if (res.status === 200) {
				this.companyList = res.body;
				if (this.companyList.length > 0) {
					this.companyFormGroup.controls['company_id'].setValue(this.companyList[0].c_id);
					this.companyId = this.companyList[0].c_id;
					this.getFollowerCountByFilter(this.companyId);
				}
			}
			else {
				this.ss.statusMessage.showStatusMessage(false, "Something went wrong cannot get company list");
			}
		});
	}

	getFollowerCountByFilter(companyId) {
		this.getOverallFolrCount(companyId);
		this.facebookGraphData = [];
		this.twitterGraphData = [];
		this.igGraphData = [];
		this.linkedInGraphData = [];
		// this.youtubeGraphData = [];
		// console.log("----------this.linkedInLineChartSettings--------" + JSON.stringify(this.linkedInLineChartSettings));
		// this.range.companyId = companyId;
		this.httpClient.request('get', "followers/get-followersWeeklyCount", 'companyId=' + companyId).subscribe((res: any) => {
			if (res.status === 200) {
				let followersData = res.body;
				let LinkedInreverseData = _.reverse(followersData);
				LinkedInreverseData = _.take(_.filter(LinkedInreverseData, { 'social_media_id': 1 }), 12);
				_.reverse(followersData);
				let LinkedIn = _.reverse(LinkedInreverseData);

				let twitterreverseData = _.reverse(followersData);
				twitterreverseData = _.take(_.filter(twitterreverseData, { 'social_media_id': 2 }), 12);
				_.reverse(followersData);
				let twitter = _.reverse(twitterreverseData);

				let facebookreverseData = _.reverse(followersData);
				facebookreverseData = _.take(_.filter(facebookreverseData, { 'social_media_id': 3 }), 12);
				_.reverse(followersData);
				let facebook = _.reverse(facebookreverseData);

				let InstagramreverseData = _.reverse(followersData);
				InstagramreverseData = _.take(_.filter(InstagramreverseData, { 'social_media_id': 4 }), 12);
				_.reverse(followersData);
				let Instagram = _.reverse(InstagramreverseData);
				// let Youtube = _.filter(followersData, { 'social_media_id': 5 });

				if (LinkedIn.length > 0) {
					this.linkedInGraphData = this.formGraphData(LinkedIn);
					this.linkedInLineChartSettings['data'] = this.linkedInGraphData;
					this.linkedInLineChartSettings = {...this.linkedInLineChartSettings};
				}
				 console.log("----------this.LinkedInGraphData--------" + JSON.stringify(this.linkedInGraphData));
				if (twitter.length > 0) {
					this.twitterGraphData = this.formGraphData(twitter);
					this.twitterLineChartSettings['data'] = this.twitterGraphData;
					this.twitterLineChartSettings = {...this.twitterLineChartSettings};

				}
				 console.log("----------this.twitterGraphData--------" + JSON.stringify(this.twitterGraphData));


				if (facebook.length > 0) {
					this.facebookGraphData = this.formGraphData(facebook);
					this.facebookLineChartSettings['data'] = this.facebookGraphData;
					this.facebookLineChartSettings = {...this.facebookLineChartSettings};
				}
				 console.log("----------this.facebookGraphData--------" + JSON.stringify(this.facebookGraphData));

				if (Instagram.length > 0) {
					this.igGraphData = this.formGraphData(Instagram);
					this.igLineChartSettings['data'] = this.igGraphData;
					this.igLineChartSettings = {...this.igLineChartSettings};
				}
				 console.log("----------this.InstagramGraphData--------" + JSON.stringify(this.igGraphData));
				// if (Youtube.length > 0) {
				// 	this.youtubeGraphData = this.formGraphData(Youtube);
				// }
				// console.log("----------this.youtubeGraphData--------" + JSON.stringify(this.youtubeGraphData));
			}
			else {
				this.ss.statusMessage.showStatusMessage(false, "Something went wrong cannot  getFollowerCountByFilter");
			}
		});
	}

	getOverallFolrCount(companyId) {
		this.overAllFolrGraphData = [];
		this.httpClient.request('get', "followers/get-overallFolsWeeklyCount", 'companyId=' + companyId).subscribe((res: any) => {
			if (res.status === 200) {
				let followersData = res.body;
			let reverseData = _.reverse(followersData);
			reverseData = _.take(reverseData, 12);
				let overallFolrData = _.reverse(reverseData);

				if (overallFolrData.length > 0) {
					this.overAllFolrGraphData = this.formGraphData(overallFolrData);
					this.overAllLineChartSettings['data'] = this.overAllFolrGraphData ;
					this.overAllLineChartSettings = {...this.overAllLineChartSettings};

				}
				 console.log("----------this.overAllFolrGraphData--------" + JSON.stringify(this.overAllFolrGraphData))
			}
			else {
				this.ss.statusMessage.showStatusMessage(false, "Something went wrong cannot  getFollowerCountByFilter");
			}
		});
	}

	formGraphData(smData) {
		let  twDates : any = _.map(smData, _.property('createdDate'));
		for(let i=0;i<twDates.length;i++){
			let date = new Date(twDates[i]);
			twDates[i] = date.getDate() + " " + (this.monthsArray[date.getMonth()]);
		}
		
		let twFC = _.map(smData, _.property('count'));
		return twFC.map((v, i) =>
			({ label: twDates[i], value: v })
		)
	}

}
