
import { Component, OnInit, Input, ViewEncapsulation, ViewChild, ElementRef, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { trigger, state, animate, transition, style, keyframes } from '@angular/animations';
import { AnimationsService } from '../../services/animations.service';
// import { ScrollBarComponent } from '../scroll-bar/scroll-bar.component';

@Component({
    selector: 'dbd-modal-popup',
    templateUrl: './modal-popup.component.html',
    styleUrls: ['./modal-popup.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger(
            'togglePopUp',
            [
                state(
                    'open',
                    style({ bottom: 0 })
                ),
                state(
                    'close',
                    style({ bottom: '100%' })
                ),
                transition(
                    'close=>open', [
                        animate(
                            '200ms',
                            keyframes([
                                style({ bottom: '-30%', offset: 0.7 }),
                                style({ bottom: 0, offset: 1 })
                            ])
                        )
                    ]
                ),
                transition(
                    'open=>close',
                    animate(
                        '200ms',
                        keyframes([
                            style({ bottom: '-30%', offset: 0.3 }),
                            style({ bottom: '80%', offset: 0.8 }),
                            style({ bottom: '100%', offset: 1 })
                        ])
                    )
                )
            ]
        ),
        // toggle the content of the pop up
        trigger(
            'togglePopUpContent',
            [
                state(
                    'open',
                    style({ opacity: 1, pointerEvents: 'auto' })
                ),
                state(
                    'close',
                    style({ opacity: 0, pointerEvents: 'none' })
                ),
                transition(
                    'close<=>open', [
                        animate(
                            '100ms'
                        )
                    ]

                )
            ]
        )
    ]
})
export class ModalPopupComponent {

    // the id which will be emitted as data in closeEvent emitted
    @Input() idd: any;

    // the trigger for the pop up open / close animation
    togglePopUp: any = "close";

    // get reference to the overlay element in the modal popup markup
    @ViewChild('overlay', /* TODO: add static flag */ {static: false}) overlay: ElementRef;

    // get reference to the scroll bar component in the modal popup markup
    // @ViewChild('scrollbar') scrollBar: ScrollBarComponent;

    // boolean to show / hide the head of the modal popup. Default they are visible
    @Input() foot: boolean = true;

    // boolean input to show / hide the foot of the modal popup. Default they are visible
    @Input() head: boolean = true;

    //input to set the width of the pop-up.Default will be 80%.
    @Input() w: number;

    //input to set the height of the pop-up.Default will be 80%.
    @Input() h: number;

    @Output() closeEvent: EventEmitter<any> = new EventEmitter();

    // timer reference to help in clearing and setting
    timer;

    minHeight;


    constructor(
        public animationService: AnimationsService,
        private cdRef: ChangeDetectorRef
    ) { }

    // close the popup
    close() {
        // this.togglePopUp = "close";
        console.log('closed modal pop up');
        this.animationService.fadeOutUp(this.overlay.nativeElement);
        clearInterval(this.timer);
        this.closeEvent.emit(this.idd);
    }

    // public method to update scroller
    updateScroller(scrollTo: any = 'relative') {
        // this.scrollBar.update(scrollTo);
        // this.minHeight = this.scrollBar.viewportSize.Y;
    }

    // open the modal popup
    open() {
        // this.togglePopUp = "open";
        this.animationService.fadeInDown(this.overlay.nativeElement, '200ms', () => { 
            // this.minHeight = this.scrollBar.viewportSize.Y; 
            this.cdRef.detectChanges();
            setTimeout(()=>{
                // this.scrollBar.update();
                this.cdRef.detectChanges();
            },500)
        }, this);
    }

}



